<template>
  <div class="uello-app-spot-route h-100">
    <spot-map />
  </div>
</template>

<script>
import { haveStop } from '@/utils';

export default {
  name: 'uello-app-spot-route',
  components: {
    SpotMap: () => import('./components/u-spot-route/SpotMap.vue'),
  },
  created() {
    if (!haveStop()) {
      this.$router.push('/app/request');
    }
  },
};
</script>
